table {
    display: inline-grid;
    grid-template-areas: 
    "head-fixed" 
    "body-scrollable";
  }
  
  thead {
    grid-area: head-fixed;
  }
  
  tbody {
    grid-area: body-scrollable;
    overflow: auto;
    height:500px;
    /* height: calc(100vh - 300px); */
  }

  tr{ 
    height:20px !important;
  }

  th, td {
    min-width: 200px;
    padding: 5px 10px;
    text-align: left;
    border: 1px solid #ccc;
  }
  
  th:first-of-type, td:first-of-type {
    min-width: 400px;
  }  